import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/layout";
import client from '../components/utils/client'

import icLogo from "../components/assets/img/pages/produtos/ic-logo.png";

// icons
import phoneIcon from "../components/assets/img/header/menu/ic-phone.png";
import wpIcon from "../components/assets/img/header/menu/ic-wp.png";
import mailIcon from "../components/assets/img/header/menu/ic-mail.png";

const SomosMais = () => {
	const [allData, setAllData] = useState({});

	useEffect(() => {
		const handleGetData = () => {
			client()
				.get("content-group/16")
				.then(res => {
					let data = res.data || [];
					data.Contents.map(item => {
						if(item.Type === "image") {
							let obj = Object.assign(item);
							obj.Value = "https://zl-ezata-api.herokuapp.com" + item.Value;
							return obj;
						}
					})
					if(data.Contents) {
						let objList = [];
						for(let i = 1; i <= 3; i++) {
							let title = data.Contents.find(item => item.Key === `somos-mais-objeto-${i}-title`);
							let text = data.Contents.find(item => item.Key === `somos-mais-objeto-${i}-text`);
							let image = data.Contents.find(item => item.Key === `somos-mais-objeto-${i}-image`);
							let obj = {
								title: title ? title.Value : "Undefined",
								text: text ? text.Value : "Undefined",
								image: image ? image.Value : icLogo,
							}
							objList.push(obj);
						}
						setAllData(allData => ({
							...allData,
							header: {
								image: data.Contents.find(item => item.Key === "somos-mais-header-image").Value,
							},
							main: {
								title: data.Contents.find(item => item.Key === "somos-mais-main-title").Value,
								text: data.Contents.find(item => item.Key === "somos-mais-main-text").Value,								
							},
							objList,
						}))
					}
				}) 
				.catch(err => {
					console.log(err);
				})

			client()
				.get("content-group/9")
				.then(res => {
					let data = res.data || [];
					data.Contents.map(item => {
						if(item.Type === "image") {
							let obj = Object.assign(item);
							obj.Value = "https://zl-ezata-api.herokuapp.com" + item.Value;
							return obj;
						}
					})
					if(data.Contents) {
						let whatsapp = data.Contents.find(item => item.Key === "geral-contato-whatsapp");
						let phoneList = data.Contents.filter(item => item.Key.includes("geral-contato-telefone")).map(item => item.Value);
						let emailList = data.Contents.filter(item => item.Key.includes("geral-contato-email")).map(item => item.Value);
						setAllData(allData => ({
							...allData,
							whatsapp: whatsapp ? whatsapp.Value : null,
							phoneList,
							emailList,
						}))
					}
				})
				.catch(err =>{
					console.log(err);
				})
		}

		handleGetData();
	}, [])

	return (
		<Layout infoPage={{ page: "sobre", sub: "somos-mais" }}>
			<div className="yes-we-can">
				<div className="header">
					<img src={allData.header ? allData.header.image : null} alt="Ícone Header" />
				</div>
				<div className=" container-60">
					{allData.main ? (
						<div className="title__page">
							<h2>{allData.main.title}</h2>
							<p>{allData.main.text}</p>
						</div>
					) : null}
					<main className="wrapper">
						{allData.objList ? (
							<section className="body">
								{allData.objList.map((item, index) => (
									<article key={index}>
										<div className="box__image">
											<img src={item.image} alt="somos mais" />
										</div>
										<div className="box__description">
											<h2>{item.title}</h2>
											<p>{item.text}</p>
										</div>
									</article>
								))}								
							</section>
						) : null}
						<section className="side-bar">
							<div>
								<h1>Fale conosco</h1>
								<a href={`tel:+55${allData.phoneList ? allData.phoneList[0].replace(/[^a-zA-Z0-9]/g, "") : "5433156990"}`} style={{display: "flex", alignItems: "center"}}>
									<img src={phoneIcon} alt="telefone"/>
									<span>{allData.phoneList ? allData.phoneList[0] : "(54) 3315-6990"}</span>
								</a>
								<a target="_blank" rel="noreferrer" href={allData.whatsapp ? allData.whatsapp : null} style={{display: "flex", alignItems: "center"}}>
									<img src={wpIcon} alt="whatsapp"/>
									<span>{allData.phoneList ? allData.phoneList[1] : "(54) 99195-0844"}</span>
								</a>
								{allData.emailList ? allData.emailList.map((item, index) => (
									<a href={`mailto:${item}`} style={{display: "flex", alignItems: "center"}}>
										<img src={mailIcon} alt="email"/>
										<span>{item}</span>
									</a>
								)) : null}
							</div>
						</section>
					</main>
				</div>
			</div>
		</Layout>
	)
};
export default SomosMais